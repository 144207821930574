<template>
  <v-container fluid style="height: 100%; display: flex; flex-direction: column">
    <page-title
      title="Equipment<sup><i class='fas fa-circle-e fs-14px teal--text ml-1 mr-2'></i></sup>"
      subtitle="Browse and Manage your Equipment!"
      :badge="this.total"
    />
    <v-row class="mt-0 mb-1" justify="space-between">
      <v-col
        md="12"
        sm="12"
        cols="12"
        class="d-flex flex-row align-center justify-end flex-wrap"
        style="gap: 0.5rem"
      >
        <filter-manager
          ref="filterManager"
          v-model="selectedFilters"
          :options.sync="options"
        ></filter-manager>
        <v-sheet height="28" width="1" color="blue-grey lighten-4" class="mx-1"></v-sheet>
        <columns-visibility-control
          :defaults="defaultHeaders"
          :withColumnsOrder="true"
          v-model="headers"
          :storageKey="storageKey"
        />
        <v-text-field
          v-model="options.search"
          label="Search"
          ref="mainSearch"
          class="table-search-field"
          @focus="mainSearchInFocus = true"
          @blur="mainSearchInFocus = false"
          :style="{
            'max-width':
              mainSearchInFocus || (options.search != '' && options.search != null)
                ? '200px'
                : '110px',
          }"
          dense
          solo
          clearable
          hide-details
          prepend-inner-icon="far fa-search"
          :loading="loadingStates.table"
        >
        </v-text-field>
        <refresh :loading="loadingStates.table" @refresh="getDataDebounced()"></refresh>
      </v-col>
    </v-row>

    <v-data-table
      style="
        overflow: auto;
        overflow: overlay;
        height: 100%;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      "
      dense
      :headers="tableHeader"
      :items="entities"
      :options.sync="options"
      :server-items-length="total"
      :loading="loadingStates.table"
      :item-class="
        (item) => {
          return item.newEquipment ? 'new-equipment-animation' : '';
        }
      "
      @click:row="rowClicked"
      class="elevation-2 equipments-table table-sticky-header-exclude-footer"
      :hide-default-footer="printing"
      :disable-pagination="printing"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'far fa-arrow-to-left',
        lastIcon: 'far fa-arrow-to-right',
        prevIcon: 'far fa-angle-left',
        nextIcon: 'far fa-angle-right',
        itemsPerPageOptions: [15, 30, 50, 100],
      }"
    >
      <template v-slot:progress>
        <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <v-tooltip right z-index="999" nudge-right="-4px">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" :class="'equipment-image-' + item.id">
              <div class="equipment-img">
                <div
                  style="height: 100%; width: 100%"
                  v-viewer
                  @click.stop
                  v-if="item.imageUrl != null && item.imageUrl != ''"
                >
                  <img :key="item.id + '_img'" :src="item.imageUrl" height="100%" width="100%" />
                </div>
                <i v-else-if="item.category != null" :class="'fad ' + item.category.icon"></i>
                <i v-else :class="'fad fa-plug'"></i>
              </div>
            </div>
          </template>
          <span>
            <v-img
              v-if="(item.imageUrl != null) & (item.imageUrl != '')"
              class="img"
              :src="item.imageUrl"
              width="250px"
              height="250px"
              contain
            ></v-img>
            <i
              v-else-if="item.category != null"
              :class="'fad ' + item.category.icon"
              style="font-size: 32px; padding: 2rem"
            ></i>
            <i v-else :class="'fad fa-plug'" style="font-size: 32px; padding: 2rem"></i>
          </span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.category`]="{ item }">
        <v-row no-gutters>
          <v-col sm="auto" class="d-flex justify-content-start align-items-center">
            <i v-if="item.category != null" :class="'equipment-icon fad ' + item.category.icon"></i>
            <i v-else :class="'equipment-icon fad fa-plug'"></i>
          </v-col>
          <v-col class="d-flex align-center">
            <span class="category-name" v-if="item.category">{{ item.category.name }}</span>
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.availability`]="{ item }">
        <equipment-availability :availability="item.availability" mini></equipment-availability>
      </template>

      <template v-slot:[`item.tag`]="{ item }">
        <div class="equipment-tag font-weight-bold">{{ item.tag }}</div>
      </template>

      <template v-slot:[`item.msrp`]="{ item }">
        <div class="font-weight-bold">{{ item.msrp | usdFormat }}</div>
      </template>

      <template v-slot:[`item.provider`]="{ item }">
        <equipment-provider
          v-if="item.provider"
          :provider-name="item.provider.name"
        ></equipment-provider>
        <i v-else :class="'far fa-times pl-2 text--disabled'"></i>
      </template>

      <template v-slot:[`item.installer`]="{ item }">
        <equipment-installer
          v-if="item.installer"
          :installer-name="item.installer.name"
        ></equipment-installer>
        <i v-else :class="'far fa-times pl-2 text--disabled'"></i>
      </template>

      <template v-slot:[`item.accessoriesCount`]="{ item }">
        <equipment-count :count="getAccessoriesCount(item)" />
      </template>

      <template v-slot:[`item.stats`]="{ item }">
        <v-tooltip right z-index="999" nudge-right="-4px">
          <template v-slot:activator="{ on, attrs }">
            <div class="d-inline-block" v-bind="attrs" v-on="on">
              <count-tag :count="item.countEquipmentUsed" icon="fa-draw-square"></count-tag>
            </div>
          </template>
          <span>
            <span class="mr-1">{{ item.countEquipmentUsed }}</span>
            {{ item.countEquipmentUsed == 1 ? "Connected Space" : "Connected Spaces" }}
            <br />
            <hr class="my-1" style="opacity: 0.2" />
            <span style="font-size: 12px">
              <i class="fad fa-swap-opacity fa-hand-pointer mr-1"></i> Click for more details!
            </span>
          </span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.manufacture.name`]="{ item }">
        <div v-if="item.manufacture != null" class="d-flex align-center" style="gap: 0.5rem">
          <v-tooltip right z-index="999" nudge-right="-4px">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <div class="company-logo-mini">
                  <div
                    style="height: 100%; width: 100%"
                    v-viewer
                    @click.stop
                    v-if="item.manufacture.logoUrl != null && item.manufacture.logoUrl != ''"
                  >
                    <img
                      :key="item.manufacture.id + '_img'"
                      :src="item.manufacture.logoUrl"
                      height="100%"
                      width="100%"
                      style="object-fit: contain"
                    />
                  </div>
                  <div style="height: 100%; width: 100%" v-viewer @click.stop v-else>
                    <img
                      :key="item.manufacture.id + '_img'"
                      src="/img/DNA_Symbol.png"
                      height="100%"
                      width="100%"
                      style="object-fit: contain"
                    />
                  </div>
                </div>
              </div>
            </template>
            <span>
              <v-img
                v-if="item.manufacture.logoUrl != null && item.manufacture.logoUrl != ''"
                :src="item.manufacture.logoUrl"
                height="250px"
                width="250px"
                contain
              ></v-img>
              <v-img v-else src="/img/DNA_Symbol.png" height="250px" width="250px" contain></v-img>
            </span>
          </v-tooltip>
          <h5 class="ma-0">{{ item.manufacture.name }}</h5>
        </div>
        <div v-else class="text--disabled">N/A</div>
      </template>

      <template v-slot:[`item.model`]="{ item }">
        <div class="equipment-model mono-font font-weight-bold">
          {{ item.model }}
        </div>
      </template>

      <template v-slot:no-data>
        <img width="500" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>
      <template v-slot:loading>
        <video width="250" muted loop autoplay style="padding: 0.5rem">
          <source src="/img/art/astronaut-zloves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Searching the Cosmos...</p>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import perms from "../../../../plugins/permissions";
import ApiService from "../services/equipments-service.js";
import equipmentHeader from "../config/tables/equipment.header";
import EquipmentProvider from "../components/EquipmentProvider.vue";
import EquipmentInstaller from "../components/EquipmentInstaller.vue";
import EquipmentAvailability from "../components/EquipmentAvailability.vue";
import FilterManager from "../../../Shared/components/FilterManager/FilterManager.vue";
import {
  EquipmentCategoryFilterSettings,
  InstallerFilterSettings,
  ProviderFilterSettings,
} from "../../../Shared/components/FilterManager/FilterSettings";

export default {
  components: {
    EquipmentProvider,
    EquipmentInstaller,
    EquipmentAvailability,
    FilterManager,
  },
  data() {
    return {
      perms: perms,
      printing: false,
      selectedFilters: [],
      storageKey: "Equipments",
      entities: [],
      total: 0,
      inventoryTimerId: null,
      defaultHeaders: [],
      mainSearchInFocus: false,
      options: {
        id: null,
        search: null,
        page: 1,
        sortBy: ["createDate"],
        sortDesc: [true],
        categoriesId: [],
      },
      lastSentOptions: null,
      storedSearch: null,
      loadingStates: {
        table: false,
      },
      headers: equipmentHeader,
    };
  },
  computed: {
    tableHeader() {
      return this.headers.filter((elm) => !elm.hidden);
    },
  },
  created() {
    this.defaultHeaders = this.headers;
    if (this.$route.query.q) this.options.search = this.$route.query.q;
    if (this.storageKey) {
      this.options.itemsPerPage = +this.$getFromLocal(
        `${this.storageKey}-itemsPerPage`,
        false,
        this.options.itemsPerPage || 15
      );
    }
  },
  mounted() {
    this.initFilterManagerSettings();
    document.querySelector("main.v-main").classList.add("sticky-main-fix");
    this.$moveable(document.querySelector(".equipments-table .v-data-table__wrapper"));

    this.$refs.mainSearch.focus();
  },
  beforeDestroy() {
    document.querySelector("main.v-main").classList.remove("sticky-main-fix");
  },
  methods: {
    initFilterManagerSettings() {
      var equipmentCategoryFilterSettings = new EquipmentCategoryFilterSettings();

      var installerFilterSettings = new InstallerFilterSettings();

      var providerFilterSettings = new ProviderFilterSettings();

      this.selectedFilters = [
        equipmentCategoryFilterSettings,
        installerFilterSettings,
        providerFilterSettings,
      ];
    },
    getAccessoriesCount(item) {
      if (item != null)
        return item.accessories.reduce((total, cur) => {
          return total + cur.count;
        }, 0);
      else return 0;
    },
    getData() {
      this.$backToTop(0, document.querySelector(".equipments-table .v-data-table__wrapper"));
      let optionsToSend = this.cloneDeep(this.options);
      if (this.storedSearch != optionsToSend.search) optionsToSend.page = 1;
      // this.$log("######################## GET DATA ########################");
      // this.$log("optionsToSend:", optionsToSend);
      this.storedSearch = optionsToSend.search;
      this.loadingStates.table = true;
      ApiService.query(this.$clean(optionsToSend, true))
        .then((resp) => {
          this.entities = resp.data.items;
          this.$log("query:", this.entities);
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
          this.$handleError(err);
        });
    },
    getDataDebounced() {
      if (this.inventoryTimerId == null) {
        this.inventoryTimerId = -1;
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.inventoryTimerId);
      // delay new call 400ms
      this.inventoryTimerId = setTimeout(() => {
        this.getData();
      }, 400);
    },
  },
  watch: {
    options: {
      handler(val) {
        this.$setToLocal(`${this.storageKey}-itemsPerPage`, val.itemsPerPage);
        var test_ToSend = this.cloneDeep(this.options);
        var test_LastSent =
          this.lastSentOptions == null
            ? this.cloneDeep(this.options)
            : this.cloneDeep(this.lastSentOptions);
        test_ToSend.page = null;
        test_LastSent.page = null;
        if (!this.isEqual(test_ToSend, test_LastSent) && this.options.page != 1) {
          this.options.page = 1;
        } else {
          this.getDataDebounced();
        }
        this.lastSentOptions = this.cloneDeep(this.options);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
@keyframes FlashBackgroundAnim {
  0% {
    background: white;
  }
  50% {
    background: rgba($orange-base, 0.16);
  }
  100% {
    background: white;
  }
}

.equipments-table {
  tbody tr:not(.v-data-table__empty-wrapper) {
    cursor: pointer;
  }
}

.v-list-item__title {
  font-size: 14px !important;
}
</style>
